import useAxisproTranslate from 'hooks/useAxisproTranslate';
import React from 'react';
import { Button, Modal, ModalFooter, Spinner } from 'react-bootstrap';
import { FaClone, FaExclamationCircle } from 'react-icons/fa';
import EstimationLineItemsAccordionView from './EstimationLineItemsAccordionView';
import PropTypes from 'prop-types';

const EstimationLineItemClone = ({
  show,
  handleCloneLineItem,
  cloneData,
  submitClonedLineItem,
  onUpdate
}) => {
  const Translate = useAxisproTranslate();
  return (
    <Modal size="xl" show={show} onHide={handleCloneLineItem}>
      <Modal.Header closeButton>
        <Modal.Title className="text-uppercase d-flex align-items-center gap-1">
          <FaClone size={18} />
          <div className="fw-bold">{Translate('Clone Item')}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EstimationLineItemsAccordionView
          entries={[cloneData]}
          disableClone={true}
          activeSubSections={[
            `maCostEvent${cloneData?.id}`,
            `addCostEvent${cloneData?.id}`
          ]}
        />
        <div className="ms-3 d-flex align-items-center gap-1">
          <FaExclamationCircle size={16} className="text-warning mb-1" />
          <span>
            {Translate(
              'Are you sure you want to clone this item? This action will create a duplicate entry.'
            )}
          </span>
        </div>
      </Modal.Body>
      <ModalFooter>
        <Button
          size="sm"
          variant="success"
          onClick={submitClonedLineItem}
          disabled={onUpdate}
          className="d-flex align-items-center"
        >
          {onUpdate ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="white"
              className="me-1"
            />
          ) : null}
          <span>{Translate(onUpdate ? 'Cloning...' : 'Proceed')}</span>
        </Button>
        <Button
          size="sm"
          variant="danger"
          onClick={() => handleCloneLineItem(false)}
        >
          {Translate('Cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EstimationLineItemClone.propTypes = {
  show: PropTypes.bool,
  handleCloneLineItem: PropTypes.func,
  cloneData: PropTypes.object,
  submitClonedLineItem: PropTypes.func,
  onUpdate: PropTypes.bool
};

export default EstimationLineItemClone;
